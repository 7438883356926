import { createSSRApp } from "vue";
import { createPinia } from "pinia";
import router from "./router";
import { createHead } from "@vueuse/head";
// import { createGtm } from "@gtm-support/vue-gtm";

import i18n from "./services/i18n";
import Flicking from "@egjs/vue3-flicking/dist/flicking.esm";
import "@egjs/vue3-flicking/dist/flicking.css";
/* Animation library */

import "animate.css";

import App from "./App.vue";

// Create a global head instance

export function createApp() {
  const app = createSSRApp(App);
  app.use(i18n);

  const pinia = createPinia();
  app.use(pinia);
  app.use(router);
  const head = createHead();
  app.use(head);
  // app.use(
  //   createGtm({
  //     id: "GTM-M55TJQVS",
  //     vueRouter: router,
  //   })
  // );

  app.component("Flicking", Flicking);
  return { app, router };
}
